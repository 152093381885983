import http from '@/http';
import qs from 'query-string';

const urlPrefix = '/api/web/pda/pickInfo';

export const PickInfoApi = {
	page: function (req: API.PickInfoQuery) {
		return http.post<API.PageData<API.PickInfo[]>>(
			`${urlPrefix}/page`,
			req
		);
	},
	callExport: function (req: API.PickInfoQuery) {
		return http(`${urlPrefix}/callExport`, {
			data: req,
			method: 'POST',
			responseType: 'blob',
			handleRes: false,
		});
	},
	viewDetail: function (id: number) {
		return http.post<API.PickDetail[]>(`${urlPrefix}/viewDetail/${id}`);
	},
};

export default PickInfoApi;
